@import "../assets/scss/abstracts/variables";
.tabs.is-centered {
    ul {
        justify-content: space-evenly !important;
    }
}

@media only screen and (max-width: $breakpoint-mobiles) {
    .tasks-table {
        overflow-x: auto;
        .columns {
            width: fit-content;
            flex-grow: 1 !important;
        }
    }
}

.w-100 {
    width: 100% !important;
}

.category-icon {
    width: 50%;
}

.p-absolute {
    position: absolute !important;
}

.top-20 {
    top: 20% !important;
}

.right-20 {
    right: 20% !important;
}

@import 'react-big-calendar/lib/sass/styles';
@import './src/assets/scss/abstracts/_variables';

.rbc-calendar {
    background-color: $white;
    padding: 0.5rem;
    border-radius: 15px;

}

.rbc-month-header {
    background-color: $primary;

    span {
        color: $white;
    }
}

.rbc-month-row {
    background-color: $white;

    .rbc-event-content {
        color: $white;
    }
}

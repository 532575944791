@import "../assets/scss/abstracts/variables";
/* ==========================================================================
1. Light mode
========================================================================== */

.loader-background {
    background: #f2f2f2 !important;
}

/* ==========================================================================
2. Dark mode
========================================================================== */
body {
    &.is-dark {

        .loader-background {
            background: lighten($dark-sidebar, 10%) !important;
        }
    }
}
